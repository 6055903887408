.scroll_down{
  position: absolute;
  right: 200px;
  bottom: 0;
  transform: translate(0,50%);
  z-index: 200;
}
#news{
  .ttl{
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    display: inline-block;
    border: 1px solid #fff;
    box-sizing: border-box;padding: 0.2em 1em;
    margin-bottom: 1em;
  }
  dl{
    display: flex;
    flex-wrap: wrap;
    dt{
      margin-right: 2em;
      font-family: 'Montserrat', sans-serif;
    }
    dd{
      cursor: pointer;
      padding-right: 2em;
      position: relative;
      &:after{
        content:'';
        display: inline-block;
        width: 0;height: 0;
        border: 0.4em solid transparent;
        border-left:0.5em solid #fff;
        vertical-align: middle;
        margin-left: 1em;
        position: absolute;
        right: 0;top:50%;
        transform: translate(0,-50%);
      }
    }
  }
  @media screen and (max-width: 640px) {
    color:#fff;
    position: absolute;
    bottom:0;
    background-color: transparent;
    padding: 0 4% 4%;
    border-bottom: none;
  }
}
#about{
  background-color: #F0F0F0;
  padding-top: 70px;
  .wrap{
    max-width: 1400px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    .innerbox{
      color:#6F6F6F;
      background-color: #fff;
      width: 55%;
      margin-right: 5%;
      margin-left: 45%;
      box-sizing: border-box;
      padding: 8% 6% 8% 20%;
      height: 100%;
      line-height: 1.8;
    }
    .img{
      position: absolute;
      left:0;top:50%;
      width:55%;
      text-align: right;
      transform: translate(0,-50%);
      img{
        width: 100%;
        max-width: 783px;
      }
    }
    .ttl{
      font-weight: normal;
      margin-bottom: 2em;
      span{
        font-family: 'Montserrat', sans-serif;
        display: block;
        color:#D30301;
        font-size: 230%;
        font-weight: bold;
        letter-spacing: 0.1em;
      }
    }
    .lead{
      font-size: 150%;
      color:#333;
      font-weight: bold;
      margin-bottom: 1em;
    }
    .text{
      margin-bottom: 2em;
    }
    .btn{
      font-family: 'Montserrat', sans-serif;
      max-width: 200px;
      text-align: center;
      transition: 0.5s;
      a{
        display: block;
        background-color: #575757;
        text-decoration: none;
        color:#fff;
        padding: 1em 2em;
        &[target="_blank"]:after{
          content: "";
          display: inline-block;
          width: 12px;height: 12px;
          background: url("../img/link.svg") no-repeat center center;
          background-size: contain;
          margin-left: 1em;
        }
      }
      &:hover{
        opacity: 0.7;
      }
    }
    @media screen and (max-width: 1400px) {
      .innerbox{
        width: 50%;height: auto;
        padding: 5% 5% 5% 10%;
      }
      .img{
        width:50%;
      }
      .pc{
        display: none;
      }
    }
    @media screen and (max-width: 960px) {
      .innerbox{
        width: 90%;
        padding:  5% 5% 20%;
        height: auto;
        margin: 0 auto;
      }
      .img{
        width: 80%;
        margin: -10% auto 20px;
        position: static;
        transform: none;
      }
      .btn{
        max-width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      font-size: 80%;
      padding-top: 30px;
    }
  }
}
#service{
  background: url("../img/service.png") no-repeat center center;
  background-size: cover;
  position: relative;
  padding: 5em 0;
  &:before{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3C3C3C;
    opacity: 0.7;
    position: absolute;
    left:0;
    top:0;
  }
  &:after{
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    background: -moz-linear-gradient(-45deg,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(21,21,21,0.4) 50.1%, rgba(21,21,21,0.4) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(21,21,21,0.4) 50.1%,rgba(21,21,21,0.4) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 50%,rgba(21,21,21,0.4) 50.1%,rgba(21,21,21,0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    opacity: 0.4;
    position: absolute;
    right:0;
    top:0;
  }
  .wrap{
    position: relative;
    z-index: 2;
    .ttl{
      color:#fff;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 0.1em;
      span{
        color:#fff;
        font-weight: normal;
        padding-top: 1em;
      }
    }
    .list{
      width: 90%;
      max-width: 1070px;
      margin: 0 auto;
      padding: 4%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-color: rgba(255,255,255,0.2);
      &>li{
        width: 30%;
        box-sizing: border-box;
        background-color: #fff;
        line-height: 1.6;
        color:#6F6F6F;
        img{width: 100%}
        dl{
          padding: 2em;
        }
        dt{
          font-family: 'Montserrat', sans-serif;
          font-weight: normal;
          margin-bottom: 1em;
          font-size: 12px;
          span{
            font-size: 150%;
            font-weight: bold;
            color:#D30301;
            display: block;
          }
        }
        dd{
          ul{
            li{
              display: inline-block;
            }
          }
        }
      }
    }
    @media screen and (max-width: 960px) {
      .list{
        justify-content: space-between;
        &>li{
          width: 47%;
          margin-bottom: 2em;
        }
      }
    }
    @media screen and (max-width: 768px) {
      font-size: 80%;
      .list{
        display: block;
        background-color: transparent;
        padding: 0;
        &>li{
          width: 100%;
          margin-bottom: 2em;
        }
      }
    }
  }
}
#profile{
  color:#333;
  .ttl{
    color:#D30301;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.1em;
    span{
      color:#333;
      font-weight: normal;
      padding-top: 1em;
    }
  }
  ul{
    margin-top: 2em;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    li{
      max-width: 200px;
      margin: 0 20px;
      a{
        transition: 0.5s;
        &:hover{
          opacity: 0.5;
        }
      }
      img{
        max-width: 100%;
      }
    }
  }
}
.modal{
  display: none;
  position: fixed; top:0; left:0;
  width: 100%; height: 100%;
  z-index: 1000;
  font-size: 18px;
  &.on{
    display: block;
  }
  .overlay{
    position: absolute; left: 0; top:0;
    width: 100%; height: 100%;
    background: rgba(0,0,0,0.7);
  }
  .container{
    position: absolute; left: 50%; top:50%;
    transform: translate3d(-50%,-50%,0);
    max-width: 800px;
    width: 96%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .close{
    display: block;
    width: 30px;height: 30px;
    position: absolute;
    right:20px;top:20px;
    z-index: 999;
    cursor: pointer;
    &:before,&:after{
      content: "";
      display: block;
      width: 100%;height: 1px;
      background-color: #A0A0A0;
      position: absolute;
      top:50%;left:50%;
    }
    &:before{
      transform:translate(-50%,-50%) rotate(-45deg);
    }
    &:after{
      transform:translate(-50%,-50%) rotate(45deg);
    }
  }
  .innerbox{
    position: relative;
    background-color: #fff;
    padding: 2em 13% 4em;
    .ttl{
      font-weight: normal;
      margin-bottom: 3em;
      padding-bottom: 0;
      color:#6F6F6F;
      font-size:14px;
      span{
        font-family: 'Montserrat', sans-serif;
        display: block;
        color:#D30301;
        font-size: 230%;
        font-weight: bold;
        letter-spacing: 0.1em;
        margin-bottom: 0.5em;
      }
    }
    .data{
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      color:#7B7B7B;
    }
    dl{
      dt{
        font-size: 120%;
        font-weight: bold;
        padding: 0.5em 0;
        border-bottom: 1px solid #D1D1D1;
        margin-bottom: 1em;
        line-height: 1.4;
      }
      dd{
        line-height: 2;
        font-size: 70%;
        letter-spacing: 0.1em;
        margin-bottom: 1em;
        .mb1{
          margin-bottom: 1em;
        }
        ul{
          li{
            position: relative;
            padding-left: 2em;
            span{
              position: absolute;
              left: 0;top:0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 736px){
    font-size: 3.4vw;
    .overlay{
      &:before{
        width: 30px;
        height: 30px;
      }
    }
    .container{
      margin-top: 80px;
      max-height: calc(90% - 100px);
      top: 0;
      display: block;
      overflow-y: scroll;
      transform: translate3d(-50%,0,0);
    }
    .innerbox {
      padding: 2em 4% 4em;
    }
  }

}
